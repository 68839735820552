<template>
  <div class="inspect">
    <!-- 有入库数据 -->
    <div class='be_put_storage_main'>
      <div class="ins_btn">
        <el-button type="primary"
                   @click='setBePutInStorage'>提交</el-button>
      </div>
      <div class="zxdInfo">
        <el-table :data="getOrderData.xz_weight_tj"
                  :default-expand-all=" true"
                  style="width: 100%; "
                  stripe>
          <el-table-column type="expand">
            <template #default="props">
              <el-table :data="props.row.wpinfo"
                        style="width: 100%;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) ">
                <el-table-column label="物品中文名"
                                 :key="props.row.name"
                                 width="120">
                  <template #default="scope">
                    <el-input v-model='scope.row.name'
                              placeholder="中文"></el-input>
                  </template>
                </el-table-column>

                <el-table-column label="英文名"
                                 width="100">
                  <template #default="scope">
                    <el-input v-model='scope.row.enname'
                              placeholder='English'></el-input>
                  </template>
                </el-table-column>

                <el-table-column label="物品类型">
                  <template #default="scope">
                    <el-select v-model="scope.row.wptype"
                               placeholder="点击选择"
                               filterable>
                      <el-option v-for="item in goodsInfo"
                                 :key="item.id"
                                 :label="item.name"
                                 :value="item.id">
                        <span style="widht:100%;display:flex">{{ item.name}}</span>
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column label="物品数量"
                                 width="100">
                  <template #default="scope">
                    <el-input v-model.number='scope.row.num'
                              placeholder="数量"></el-input>
                  </template>
                </el-table-column>

                <el-table-column label="数量单位"
                                 width="120"
                                 type="index">
                  <template #default="scope">
                    <el-select v-model="scope.row.unit"
                               placeholder="请选择">
                      <el-option v-for="(item,index) in unitData"
                                 :key="index"
                                 :label="item"
                                 :value="item">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>

                <el-table-column prop="unitprice"
                                 label="物品单价"
                                 width="100">
                  <template #default="scope">
                    <el-input v-model.number='scope.row.unitprice'
                              placeholder="单价"></el-input>
                  </template>
                </el-table-column>

                <el-table-column prop="unitprice"
                                 label="物品总价">
                  <template #default="scope">
                    <span style='color:#409eff'>
                      {{scope.row.unitprice*scope.row.num !== 0?
                  scope.row.totalamount = scope.row.unitprice*scope.row.num:
                  0}}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column label="操作">

                </el-table-column>

              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="箱号"
                           width="150"
                           type="index">

          </el-table-column>
          <el-table-column label="长度"
                           width="110"
                           height='50'>
            <template #default="scope">
              <el-input v-model="scope.row.chang"
                        placeholder="CM"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="宽度"
                           width="110"
                           height='50'>
            <template #default="scope">
              <el-input v-model="scope.row.kuan"
                        placeholder="CM"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="高度"
                           width="110"
                           height='50'>
            <template #default="scope">
              <el-input v-model="scope.row.gao"
                        placeholder="CM"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="重量"
                           width="110"
                           height='50'>
            <template #default="scope">
              <el-input v-model="scope.row.weight"
                        placeholder="KG"></el-input>
            </template>
          </el-table-column>

          <el-table-column label="子单号"
                           height='50'>
            <template #default="scope">
              <el-input v-model="scope.row.zdnum"
                        placeholder="(可选)请输入子单号">
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           height='50'>

          </el-table-column>
        </el-table>
      </div>
      <el-collapse v-model="activeNames"
                   class="goodinfo">
        <el-collapse-item title="物品说明"
                          name="1">
          <div>
            <el-table :data="goodsInfo"
                      style="width: 100%;padding-bottom: 70px;">
              <el-table-column prop="name"
                               label="物品名"
                               width="180">
              </el-table-column>
              <el-table-column prop="info"
                               label="说明">
              </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { ObjToArr } from '@/assets/common'
import { postOrderDetails, postGoodsType, postInspect } from '@/request/api'
export default {
  data () {
    return {
      goodsInfo: [],
      activeNames: '2',
      //-------------入库数据-----------
      rk_data: {
        kdname: '',
        zxd: [{
          chang: '',
          kuan: '',
          gao: '',
          weight: '',
          zdnum: '',
        }],
        wpinfo: []
      },
      // ---------订单数据---------
      getOrderData: {},
      // ---------订单ID---------
      orderID: '',
      // ---------仓库ID---------
      wid: '',
    }
  },
  computed: {
    unitData () {
      return this.$store.state.numberUnit
    }
  },
  methods: {
    // -------------提交入库-----------
    async setBePutInStorage () {
      this.fullscreenLoading = true
      let res = await postInspect({
        ckid: this.wid,
        pd: 'ok',
        orderid: this.getOrderData.id,
        zxdinfo: JSON.stringify(this.getOrderData.xz_weight_tj),
      })
      this.fullscreenLoading = false
      if (res.errno == 0) {
        this.$message.success(res.message)
        if (this.getOrderData.type == 1) {
          this.otherOperation = true
        } else {
          setTimeout(() => {
            this.$router.go(0)
          }, 1000);
        }
      } else {
        this.$message.error(res.message)
      }
      this.fullscreenLoading = false
    },
    // -------------获取物品类型-----------
    async getGoodsType () {
      let res = await postGoodsType({
        clid: this.wid,
        orderid: this.orderID,
      })
      if (res.errno == 0) {
        this.goodsInfo = ObjToArr(res.wptypelist)
        this.getOrderDetails()
      }
    },
    // -------------获取订单数据-----------
    async getOrderDetails () {
      let res = await postOrderDetails({
        ckid: this.wid,
        op: 'orderinfo',
        pd: 'getinfo',
        orderid: this.orderID
      })
      if (res.id) {
        this.getOrderData = res
      } else {
        console.log(res);
      }
    }
  },
  async created () {
    let wid = JSON.parse(localStorage.getItem('ty_warehouseData'))
    let cklist = JSON.parse(localStorage.getItem('cklist'))
    //  --------- 
    if (cklist) {
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
    } else {
      await this.$store.dispatch('cklist')
      localStorage.setItem('cklist', JSON.stringify(this.$store.state.warehouse))
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
    }
    if (this.$route.query.id) {
      this.orderID = this.$route.query.id
      this.getGoodsType()
    } else {
      this.$message.error('未获取到仓库ID，请退出重试')
      setTimeout(() => {
        this.$router.push({
          name: 'order'
        })
      }, 1000);
      return
    }
  }
}
</script>

<style lang='scss'>
.inspect {
  width: 100%;
  height: 100%;
  overflow: auto;
  .be_put_storage_main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    .ins_btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .zxdInfo {
      width: 100%;
      margin-top: 30px;
    }
    .goodinfo {
      margin-top: 100px;
    }
  }
}
</style>